html[data-theme="dark"] {
  --text-color-normal: hsl(210, 10%, 62%);
  --text-color-light: hsl(210, 15%, 35%);
  --text-color-richer: hsl(210, 50%, 72%);
  --text-color-highlight: hsl(25, 70%, 45%);
}
@media (max-width: 768px) {
  .web-view {
    display: none;
  }
  
.ion-page {
  background-color: #e8e8e8;
}
}
@media (min-width: 992px) {
  .mobile-view {
    display: none;
  }
  ion-tab-bar.IonTabBar.md.hydrated {
    display: none;
  }
}

input:autofill {
  color: black !important;
}
input {
  background: white;
}

.tabBar_image {
  width: 27px;
  height: 27px;
  margin: 5px auto 0px;
}
ion-label.sc-ion-label-ios-h.sc-ion-label-ios-s.ios.hydrated {
  font-size: 12px;
  color: gray;
}
.hydrated {
  visibility: inherit;
}

.mobile-view {
  height: 100%;
}
.flex_column {
  display: flex;
  flex-direction: column;
  width: 178%;
}
.web-view {
  width: 1440px;
  overflow-y: auto;
  margin: 0 auto;
  height: 100%;
}
.react-calendar__navigation button {
  min-width: 30px !important;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  border-radius: 50%;
  /* max-width: 13.6%!important; */
}
button.react-calendar__tile {
  max-width: 13.6% !important;
  margin-left: 0.5%;
}
ion-icon {
  cursor: pointer;
}
.d-hotline.h-btn:focus {
  outline: none !important;
  border: none !important;
}

ion-tab-bar.IonTabBar {
  border-top: 1px solid #3AB34A!important;
}