.upcoming_title,.complete_title{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;

}
.upcoming_title{
 color: #3AB34A;
 border-bottom: 1.5px solid #3AB34A;
 padding-bottom: 10px;
}