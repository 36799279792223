.d-flex-pickupdetailsss {
  display: flex;
  justify-content: space-between;
}
.d-flex-pickupdetails P {
  align-self: center;
}
.header_p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: green;
  padding-right: 15px;
  margin-top: 10px;

  color: #3ab34a;
}
.upcoming-details {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.block_box_after::after {
  display: none;
}

img.router {
  margin-top: 10px;
}

.small-appaliance {
  height: 130px;
}
.payment_title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 14px;
  margin-bottom: 0px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
.payment-details {
  margin: 3px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
.block_box img{
  max-height: 35px;
}
.view_pickup_image{
  width: 85px;
  height:40px
}
