ion-col.setLocation.md.hydrated {
  margin-left: 0.5rem;
}
ion-button.confirm_btn {
  margin-top: 0.9rem;
  margin-bottom: 1rem;
}
input.detail_input {
  border: none;
  width: 100%;
  font-size: 12px;
  padding: 0.5rem 2px;
  font-weight: 500;
  background-color: white;
  outline: none;
}
.input_div {
  border-bottom: 1px solid #8080804f;
  margin-bottom: .7rem;
  position: relative;
}
.border_css {
  width: 100%;
  margin: 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  
}
.border_css::after {
  content: "";
  width: 0%;
  background: red;
  height: 2px;
  display: block;
  transition: all 0.4s;
}

.detail_input:focus + .border_css {
  width: 100%;
  margin-top: 5rem;
  transition: all 0.4s;
  background: red;
  height: 20px;
}

ion-grid.address_grid{
  position: absolute;
  width: 100%;
  transition: transform 0.2s;
  z-index: 105;
  background: white;
  height: 316px;
}
ion-grid.address_grid.full {
  height: 100%;
  margin-top: 0rem !important;
  transition: transform 0.2s !important;
  transform: translateY(-53%);
}
ion-button.saveBtn.md.button.button-block.button-solid.ion-activatable.ion-focusable.hydrated {
  height: 45px;
  margin-top: 2rem;
}
.tag_item {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.tag_item_self {
  display: flex;
  border-bottom: 2px solid rgb(202, 202, 202);
  font-weight: 500;
}
.gmnoprint {
  display: none;
}
ion-col.setLocation.heading.md.hydrated {
  margin-left: 0px;
}
svg.MuiSvgIcon-root {
  font-size: 20px;
  cursor: pointer;
}
.search_text {
  margin-bottom: 0px;
  display: block;
}
.search_text_label{
font-size: 14px;
}
.search_text_desc{
  font-size: 12px;
  display: block;
  font-weight: 400;
}
.font_weight_bold {
  font-weight: bold;
}
.margin-5 {
  margin: 0px 5px 5px;
}
.mr-5 {
  margin-left: 0.5rem;
}
.font_color {
  font-weight: bold;
  color: #909090;
}
.tag_item_self.cursor-pointer.selectTag {
  color: green;
  border-bottom: 2px solid green;
}
#fullOther {
  width: 100%;
  position: absolute;
  background-color: white;
  transition: all 0.3s;
}
.other_input {
  height: 25px;
  color: black;
  font-size: 14px;
  margin-left: 19px;
  border: none;
  outline: none;
  background-color: rgba(238, 238, 238, 0.61);
  padding: 0.5rem;
}
.other_input:focus {
  border: none;
  outline: none;
}
.other_icon {
  width: 34px;
  height: 33px;
  margin-right: 1rem;
  margin-left: 5px;
  margin-top: -4px;
}
.other_input {
  width: 0%;
  transition: all 1s;
}
.other_input.full {
  width: 100%;
  transition: all 1s;
}
.map_design {
  height: 36vh;
}
.mobile_view_map {
  height: 53%;
}
