nav.navbar li {
  font-family: Inter;
  letter-spacing: -1px;
}
.localHeader_main {
  background: #f6faf5;
  box-shadow: 0px 1px 12px #2c6b34;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 125252525;
}
.header_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem;
  margin-bottom: 0px;
}
.header_image_div {
  display: flex;
  align-items: center;
}
.local_header_logo {
  height: 48px;
}
.header_ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.header_ul li {
  margin: auto 1.2rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  color: #257430;
  cursor: pointer;
}
.header_ul li.active {
  font-weight: 600;
}
.local_location {
  display: flex;
  align-items: center;
  background: #f6faf5;
  border: 1px solid #cfcfcf;
  box-sizing: border-box;
  border-radius: 7px 7px 0px 0px;
  height: 34px;
  width: 280px;
  margin-right: 1rem;
  position: relative;
}
.location_pin_icon {
  height: 25px;
  margin-left: 3px;
}
.local_location input {
  border: none;
  background: #f6faf5;
  font-weight: 400;
  font-size: 16px;
}
.local_location input:focus {
  outline: none;
  border: none;
}
.location_dropdown_ul {
  position: absolute;
  width: 100%;
  left: 0;
  top: 2rem;
  background: #f6faf5;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  list-style: none;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
.location_dropdown_ul li {
  padding: 0.5rem 1rem;
  margin: 0.2rem 0rem;
}
li.dropdown_current_location {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #5b5b5b;
  text-transform: uppercase;
  padding: 0.4rem 1rem;
  border-bottom: 1px solid rgb(209, 209, 209);
  margin-top: 0px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
li.dropdown_current_location,
li.dropdown_search_location {
  cursor: pointer;
}
li.dropdown_current_location:hover,
li.dropdown_search_location:hover {
  background-color: #e8e8e8;
}
li.dropdown_current_location img {
  width: 1.4rem;
  margin-left: 1px;
  margin-right: 0.3rem;
}
.dropdown_search_location img {
  width: 1.7rem;
}

.local_location input:focus + ul {
  transform: scaleY(1);
}
.local_header_search {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 0.7rem;
  font-weight: 500;
}
.local_header_search input {
  border: none;
  padding: 0.6rem;
  background: #f6faf5;
  margin: 0rem 0.4rem 0rem 0rem;
  padding-left: 0.4rem;
}
.local_location {
  margin-left: auto;
}
.local_header_search input:focus {
  border: none;
  outline: none;
}
label {
  margin-bottom: 0px;
}
.search_outline_icon {
  font-size: 1.4rem;
  cursor: pointer;
}
@media (max-width: 992px) {
  .local_location,
  .local_header_search,
  .profileName_pic {
    margin-left: 0px;
  }
  .header_ul li {
    margin: 6px 0px;
  }
}
@media (max-width: 576px) {
  .localHeader_main{
    width:102%
  }
}

.footer-address{
  padding-bottom: 50px;
}

.footer-address p{
  font-family: Inter;
  font-size: 16px;
}