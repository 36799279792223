.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 90%;
  margin: auto;
  text-align: justify;
}
