.local_rate_div {
  background: #f6faf5;
  height: 100vh;
  overflow: auto;
}
.local_rate_block_box {
  background: #f6faf5;
  box-shadow: 2px 2px 4px #16361a;
  border-radius: 8px;
  margin-bottom: 1rem;
  height: 110px;
  margin: 1rem 1.6rem;
}
.local_rate_block_box::after {
  background: none;
}

.local_rate_title {
  font-family: Inter;
  margin:auto;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #5b5b5b;
  text-align: center;
  /* margin-bottom: 1.2rem; */
}
.box_outside {
  width: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
}

.local_specific_rate_title {
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  width: 150px;
  color: #000000;
  white-space: break-spaces;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
img.rate_image {
  height: 50%;
  left: 23%;
}
.local_rate_block_box_outside_box {
  display: flex;
  flex-wrap: wrap;
}
.block_box p:last-child {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3ab34a;
  margin: 0px 0px 5px;
}

.web_view_item_rate{
  /* flex-direction: row!important; */
  display: grid;
  padding: 30px;
  gap: 6px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  width: min(90%, 1100px);
  margin: auto;
  overflow-x:auto;
}

.web_box_outside {
  width: 200px!important;
  flex-direction: column!important;
  margin-right: 1rem;
}
.web_local_rate_block_box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-direction: column;
  width: 150px;
  margin:1rem 0px;
}
.web_local_rate_block_box:first-child{
  margin-left: 1rem;
}
.rate_price{
  margin-top: 10px!important;
}
@media (max-width: 992px) {
  .box_outside {
    margin-right: 0.5rem;
  }
  .local_specific_rate_title {
    width: 110px;
  }
}
