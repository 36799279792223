ion-header.d-flex-pickupdetails.ios.header-ios.header-collapse-none.hydrated {
  background: white;
}
.d-flex-pickupdetails {
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
  background-color: white;
}
.d-flex-pickupdetails img {
  width: 10px;
  height: 18px;
  align-self: center;
  cursor: pointer;
}
.d-flex-pickupdetails p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 10px;
  cursor: pointer;
}
.upcoming_pickup_detail_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-left: 4%;
  margin-top: 0px;
  margin-bottom: 0px;
}
ion-row.btn_box ion-col ion-button {
  width: 100%;
}
.add_more_item {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin-top: 30px;
  margin-left: 10px;
}
.plus.edit {
  background: #5d5d5d;
  font-size: 10px !important;
  border-radius: 7px;
  padding: 4px;
  margin-top: 0.9rem;
}
.d-none::after {
  display: none;
}
img.upcomingDetainlsImg {
  margin-left: 20px;
  margin-top: 4rem;
}
.upcomingPage_text {
  background-color: green;
  color: #fff;
  text-align: center;
  padding: 15px;
}
.input.search_bar {
  margin-top: 1rem;
}

.sub_title {
  font-weight: 600;
  width: 90%;
  margin: 1rem auto 0rem;
}
img.description_icon {
  width: 20px;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}
.sub_title_h6 {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.sub_title_h6.left {
  justify-content: flex-end;
}
.left_side {
  width: 247px;
  margin-right: auto;
  margin-left: 5%;
}
.right_side {
  width: 247px;
  margin-right: 5%;
  margin-left: auto;
}
