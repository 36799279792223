.submitBtn {
  color: #fff;
  text-align: center;
  border: none;
  text-transform: uppercase;
  background: none;
  font-size: 16px;
}
.edit_profile_btn{
  bottom:43px;
}

@media (min-width: 768px) {
  ion-color.ion-color-success.md.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated,
  ion-button.bottom_Btn.ion-color.ion-color-success.ios.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
    bottom: 0 !important;
  }
}
