.web_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  background: white;
}
.web_header .header_logo {
  margin-left: 40px;
}
.web_header_ul {
  list-style: none;
  padding: 10px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.web_header_ul li {
  padding: 0px 5px;
  font-size: 14px;
  font-weight: 500;
}
.web_header_ul li:last-child {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: gray;
  margin-left: 10px;
}
.web_header_ul li img {
  max-width: 100%;
}
.logo_search {
  display: flex;
  align-items: center;
  width: 70%;
}
.userName {
  margin-left: 20px;
}
.display_flex {
  display: flex;
  align-items: center;
}
.kg {
  color: #3ab34a;
}
.payment {
  margin-bottom: 0px;
}
.price {
  margin-bottom: 2rem;
}
img.item_pic {
  max-width: 60px;
  margin-right: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}
.text-center {
  text-align: center;
  font-weight: bold;
}
img.emptyCartImage {
  width: 50%;
  text-align: center;
  margin: 25%;
  margin-top: 50%;
  margin-bottom: 10%;
}
li.cursor-pointer.profile_image {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  background: #359c43;
  font-weight: bold;
  line-height: 1.7;
}
.close-cart-icon,
.edit_btn {
  margin-left: auto;
}
ul.cart_ul.height-90 {
  height: 90%;
  padding-right: 0;
}
svg.MuiSvgIcon-root.notifyBell {
  margin: auto;
  font-size: 60px;
  margin-left: 38%;
  margin-top: 70%;
  margin-bottom: 1rem;
  color: #3ab34a;
}
p.margin_bottom_0.text-truncate {
  width: 280px;
  font-size: 13px;
  color: #676666;
  text-transform: uppercase;
  display: block;
  padding-left: 5px;
  margin-left: 5px;
  color: #686b78;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.two_section.web {
  margin-left: 0rem;
  display: flex;
  cursor: pointer;
  margin-top: 0.4rem;
}
.two_section.web img {
  width: 26px;
  margin-top: -2px;
  margin-left: -4px;
  color: #3ab34a;
}
.two_section.web span {
  font-size: 14px;
  margin-top: 0.5rem;
  border-bottom: 2px solid gray;
}

.two_section:hover span {
  color: #3ab34a;
  border-bottom: 2px solid #3ab34a;
}

/* SIdebar css */
.location_sidebar {
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 466px;
  height: 100% !important;
  padding-bottom: 10px;
  overflow-y: auto;
  z-index: 21474836001265;
  box-shadow: -6px 5px 13px #00000014;
  background-color: white;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
.location_sidebar.open {
  transform: translateX(0rem);
  transition: all 0.5s;
  height: -webkit-fill-available;
}
.sidebar_overlayClose {
  display: none;
  transition: all 0.3;
}
.sidebar_overlayOpen {
  position: fixed;
  z-index: 12509259059;
  width: 100%;
  top: 0;
  height: 100vh;
  background-color: #0c0909a2;
  transition: all 0.3;
}
.sidebar-content {
  padding: 2rem;
  margin: 2rem 1rem 0rem 2rem;
  height: 95vh;
}
@media screen and (max-width: 480px) {
  .location_sidebar{
    width: 100vw;
  }
  .sidebar-content {
    padding: 1rem;
    margin: 0px auto;
    height: 95vh;
  }
  .address_search_box{
    width:95vw;
  }
  .address_content{
    width: 100%!important;
    padding-right: 0!important;
  }
}

.address_search_box {
  box-shadow: 0px 4px 6px #b7b7b754;
  border: 1px solid #d2d2d2;
  display: flex;
  align-items: center;
  width: 102%;
}
.address_content {
  height: 74vh;
  overflow-y: auto;
  width: 110%;
  padding-right: 1rem;
  margin-top: 0.5rem;
  box-shadow: 0px -4px 6px #dadada3a;
}
.saved_address_icon_web {
  margin-bottom: 0px;
}
.ionside_part {
  width: 95%;
}
.close_btn {
  font-size: 25px;
  cursor: pointer;
}
.close_icon {
  color: black;
  font-size: 28px;
  cursor: pointer;
  margin-bottom: 1rem;
}
input.searchBox_input {
  width: 90%;
  padding: 0.9rem;
  border-radius: 0px;
  font-size: 16px;
  outline: none;
  border: none;
}
input.searchBox_input::placeholder {
  color: rgb(170, 169, 169);
}
input.searchBox_input:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.current_location,
.saved_address_web {
  display: flex;
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px dashed #d2d2d2;
}
.saved_address_web:last-child {
  border-bottom: none;
}
.saved_address_box,
.current_location {
  border: 1px solid #d2d2d2;
  padding: 1rem;
}
.saved_address_box {
  margin-top: 1rem;
  position: relative;
}

.current_location p,
.saved_address_web p {
  margin-bottom: 0px;
  margin-left: 1rem;
}
.ion_web_icon {
  font-size: 24px;
  margin-bottom: 1.1rem;
}
.location_text {
  font-size: 16px;
  font-weight: 500;
  color: #282c3f;
}
.current_location:hover .location_text,
.saved_address_web:hover .location_text {
  color: #01b118;
}
.location_text span {
  display: block;
  font-size: 13px;
  color: #93959f;
  line-height: 1.3;
  padding-top: 5px;
}
.saved_address_web_span {
  font-size: 12px;
  color: #7e808c;
  position: absolute;
  top: 0.7rem;
  left: 3.5rem;
}
.add_more_details {
  border-color: #01b118;
  text-align: center;
  color: #01b118;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding: 1rem;
}
.add_more_details small {
  color: #01b118;
}
.skip_btn small {
  color: white;
}
.skip_btn {
  text-align: center;
  background-color: #01b118;
  color: white;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding: 1rem;
  border: 1px solid #01b118;
  width: 100%;
}
.skip_btn:hover {
  box-shadow: 0 2px 5px rgb(146, 146, 146);
}
.current_location.search {
  padding: 1rem;
}
.current_location.search input {
  border: none;
  width: 100%;
}
.current_location.search input:focus {
  border: none;
  outline: none;
}
p.location_text.text-truncate {
  margin-left: 0px;
  width: 100%;
}
.sidebar_content_top {
  display: flex;
  align-items: center;
}
.title_set {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.first_title,
.second_title,
.third_title {
  padding: 1rem;
  border: 1px solid #d2d2d2;
  width: 33.3%;
  border-top: none;
  font-size: 14.5px;
  border-right: none;
  align-self: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.747);
  cursor: pointer;
}
.first_title span,
.second_title span,
.third_title span {
  margin-left: 3px;
}
.first_title:hover,
.second_title:hover,
.third_title:hover {
  background-color: rgba(0, 0, 0, 0.808);
  color: white;
}

.third_title {
  border-right: 1px solid #d2d2d2;
}
.pincode_box{
  box-shadow: none;
  width: 100%;
  border-top: none;
}
.pincode_box input{
  -webkit-appearance: none;
  -moz-appearance: textfield;
  font-weight: 700;
}
.house_box input {
  border-top: none !important;
  box-shadow: none;
  padding: 1.4rem 1rem;
  font-weight: 500;
}
.landmark_box input {
  border-top: none !important;
  box-shadow: none;
  padding: 1.4rem 1rem;
  font-weight: 500;
}
.house_box,
.landmark_box {
  box-shadow: none;
  width: 100%;
  border-top: none;
}
.house_box input:focus,
.landmark_box input:focus {
  box-shadow: none;
  outline: none;
}
.active_category {
  background: rgba(0, 0, 0, 0.733);
  color: white;
}
.cancel_color {
  color: #01b118;
}
.Other_click {
  display: flex;
  padding: 0rem 0rem;
  align-items: center;
}
.first_other {
  font-size: 15px;
  width: 25%;
  border: 1px solid #d2d2d2;
  padding: 1rem 0.5rem;
  border-top: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.733);
}
.second_other {
  width: 65%;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 0.8rem 0.5rem;
}
.second_other input {
  border: none;
  font-size: 12px;
  padding-left: 1rem;
  font-weight: 500;
}
.second_other input:focus {
  border: none;
  outline: none;
}
.third_other {
  border-right: 1px solid #d2d2d2;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid #d2d2d2;
  cursor: pointer;
}
.third_other p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 500;
}
button.current_location.skip_btn:focus {
  border: none;
  outline: none;
}
p.error_para.error_location::before {
  content: "";
  display: block;
  height: 10px;
  background: #5d8ed5;
  width: 10px;
  top: -5px;
  left: 3rem;
  transform: rotate(45deg);
  position: absolute;
}
p.error_para.error_location {
  background: #5d8ed5;
  color: white;
  margin: 0;
  padding: 1rem;
  position: relative;
}
.header-first.web_title {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  min-width: 123px;
}
.header-first.web_title p {
  margin-bottom: 0;
  font-size: 12px;
  cursor: pointer;
}
.header-first.web_title .arrow_icon {
  width: 20px;
  cursor: pointer;
}
img.web_view_logo {
  min-width: 180px;
}
ion-icon.cartIcon_close {
  font-size: 2rem;
  margin: 0rem 0.3rem;
  cursor: pointer;
}
.item_text {
  width: 80%;
}
.web_search_result {
  margin-top: 1rem;
}
