.description-setting {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 90%;
  line-height: 25px;
  color: #000000;
  margin: auto;
}
.code {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
}
.settingImg {
  display: flex;
  justify-content: center;
}
.code_key {
  width: 90%;
  margin: auto;
}
.code_key p,
.code_key h3 {
  margin: 0px;
}
p.code {
  margin-top: 10px;
}
.d-flex-pickupdetails P {
  align-self: center;
  margin: 0px;
  padding: 13px;
}
.share_btn {
  margin-bottom: 3rem;
  margin-top: 1rem;
}
