.logo_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.logo {
  width: 166px;
  height: 48px;
}
.middle_image {
  width: 253px;
  height: 182px;
  margin-top: 3rem;
}
.web_view_margin {
  margin: 0;
}
.welcome_page_title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  width: 240px;
  margin: 2.5rem auto 1rem;
}
.welcome_page_subtitle {
  font-family: Montserrat;
  width: 240px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 1.5rem auto;
  color: #000000;
}
.welcome_page_ul {
  list-style: none;
  width: 266px;
  height: 15px;
  margin: auto;
  padding: 0px;
}
.welcome_page_ul li {
  display: inline;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #5b5b5b;
  padding: 0px 5px;
  border-right: 1px solid #5b5b5b;
}
.welcome_page_ul li:last-child {
  border: none;
}
ion-button.bottom_Btn.ion-color.ion-color-success.md.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated,
ion-button.bottom_Btn.ion-color.ion-color-success.ios.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
  position: absolute;
  width: 100%;

  margin: 0;
}
ion-button.bottom_Btn.Edit-card-button.ion-color.ion-color-success.ios.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
  bottom: 2.7rem;
}
#bottom_btn {
  bottom: 0;
}
.content_box_login {
  margin-top: 3%;
  margin-right: 10%;
}
.login_box .login_title {
  font-size: 30px;
  font-size: 500;
}
.or_create_account {
  font-size: 13px;
  font-weight: 500;
}
.create_account {
  color: #3ab34a;
}
.or_create_account::after {
  content: "";
  display: block;
  width: 35px;
  height: 2px;
  background-color: black;
  margin-top: 1rem;
}
.webview_number_input_box {
  width: 100%;
  margin-top: 3rem;
}

#float-label input {
  border: 1px solid rgb(196, 195, 195);
}
.last_child {
  margin-top: 1rem;
}

.webview_number_input:focus {
  outline: none;
  border: none;
}
.Ion_label_float_text {
  color: gray !important;
}
.login_btn_web_view {
  width: 100%;
  background-color: #3ab34a;
  color: white;
  font-weight: 600;
  margin-top: 1rem;
  height: 50px;
}
.content_box_login {
  margin-left: 7rem;
  width: 381px;
}

.icon_back {
  cursor: pointer;
  font-size: 22px;
}
small {
  color: darkred;
  font-size: 67%;
}
#float-label {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  margin-top: 1rem;
}

#float-label input {
  width: 100%;
  height: 70px;
  padding: 14px 16px 0 20px;
  outline: 0;
  border: none;
  background: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}
#float-label {
  position: relative;
}

#float-label label {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0px 29px;
  color: #999;
  font-weight: bold;
  pointer-events: none;
}
#float-label label {
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-weight: bold;
  position: absolute;
  transform: translate(0, 26px) scale(1);
}

#float-label:focus-within label {
  transform: translate(0, 12px) scale(0.75);
}
label.Active {
  transform: translate(0, 12px) scale(0.75) !important;
}
.input_text {
  font-weight: bold;
}
.signup_box {
  margin-top: -10% !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: black;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: /*your box-shadow*/ 0 0 0 50px white inset;
  -webkit-text-fill-color: black;
}
input#otp:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #efefef inset;
}
input#otp:-webkit-autofill:focus {
  -webkit-box-shadow: /*your box-shadow*/ 0 0 0 50px #efefef inset;
}
