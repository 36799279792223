.d-flex-header-twopage {
  display: flex;
  flex-direction: row;
}
/* ion-header.md.header-md.header-collapse-none.hydrated{
    position: initial!important;
} */
.header-md::after {
  background-image: none !important;
}
input.searchbar-input.sc-ion-searchbar-md {
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px;
  padding: 5px 43px;
}
.cart_icons {
  height: 24px;
  width: 24px;
  margin-top: 21px;
  margin-left: 6px;
}
.container {
  background-color: white;
}

input.searchbar-input.sc-ion-searchbar-ios {
  border: 1px solid #b9b8b8;
  box-sizing: border-box;
  border-radius: 8px;
  background: transparent;
}
ion-col.d-flex-header-twopage.ios.hydrated {
  padding-left: 0px;
}

.search_bar {
  width: 127%;
}
input.searchbar-input {
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid #A0A0A0;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 2.5rem;
}
ion-icon.searchbar-search-icon.sc-ion-searchbar-ios.ios.hydrated {
  margin-left: 0.4rem;
  margin-top: 3px;
  color:#C9C9C9
}
ion-badge.badgeStyle.ion-color.ion-color-danger.ios.hydrated {
  position: absolute;
  top: -15%;
  right: -26%;
}

.cartIcon {
  position: relative;
  display: none;
}
.cart_icon_div {
  padding-top: 0.8rem;
}
.middle {
  margin: 0 1rem;
}
