.list_item {
  padding: 10px 20px 10px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.profile_header {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 0px;
  border-bottom: 1px solid #c9c9c9;
  background: white;
  padding-bottom: 10px;
}
.profile_header h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 0px;
}
.profile_header p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
}
p.edit {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3ab34a;
}

.list_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list_item_d-flex {
  display: flex;
  flex-direction: row;
}
.list_item_d-flex h3 {
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-transform: capitalize;

  color: #000000;
}
.list_item_d-flex p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height, or 125% */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #6d6d6d;
}

.app_info {
  margin-top: 30px;
}
.app_info p:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3ab34a;
}
.app_info p:last-child {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #c9c9c9;
}
ion-header.ios.header-ios.header-collapse-none.hydrated {
  background: white !important;
}
.alert-button-group.sc-ion-alert-md {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
h2.alert-title {
  text-align: center;
}
.app_version{
  display: block;
}
.ripple-parent {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .app_version{
    display: none;
  }
  .webviewProfileHeader,
  .webviewProfileBody {
    padding: 0px 2rem;
    background: white;
  }
  .webviewProfileBody .list_item {
    padding-top: 21px;
  }
  .app_info {
    padding-left: 2rem;
  }
}
