.input_form {
  margin-bottom: 30px;
}
p.input_title {
  margin: 0px 0px 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #808080;
}
.input_form input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  border: none;
  line-height: 20px;
  width: 100%;
  color: #000000;
  border-bottom: 1px solid #c9c9c9;
  outline: none;
}
ion-header#edit_card_header {
  background: white !important;
}
