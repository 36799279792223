#fc_frame,
#fc_frame.fc-widget-normal {
  bottom: 9%;
}
.image_div {
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  #fc_frame,
  #fc_frame.fc-widget-normal {
    right: 42px;
    bottom: 22px;
  }
}
