.confirmation_box {
  text-align: center;
}
.confirmation_title {
  margin-top: -20px;
}
.confirmation_image {
  height: 303px;
  width: 289px;
}
.btn_box {
  margin: 2rem 0px;
}

ion-button.btn-confirmation.ion-color.ion-color-success.md.button.button-solid.ion-activatable.ion-focusable.hydrated {
  font-size: 12px;
  width: 100%;
}
