.header_row {
  padding: 10px 0px 0px;
}
.locationStyle {
  font-weight: 500;
  font-size: 12px;
  margin: 4px 0px 4px 10px;
}
.header-full-address{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px!important;
  color: #9a9a9a!important;
  margin-bottom: 0px;
}

.header_left p {
  font-size: 14px;
  color: black;
  margin-left: 15px;
  font-weight: 500;
  height: 22px;
  overflow: hidden;
  margin:0px 10px 0px 17px;
}
.d-flex-header {
  display: flex;
  justify-content: space-between;
}
.header-first {
  display: flex;
  align-items: center;
}
.header_left .location_icon {
  height: 26px;
  width: 18px;
}
.two_section {
  width: 92%;
  margin-top: -0.6rem;
  margin-left: -1rem;
}
.header_left .arrow_icon {
  height: 8px;
  width: 14px;
}
.cart_icon {
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: black;
}
.header_icon_image {
  margin-top: 0px !important;
}
.header_icon_badge {
  top: -0.4rem !important;
}

.header_left .arrow_iconss {
  margin-top: 0px;
  margin-left: 9px;
}
.margin_bottom_0 {
  margin: 0px;
  font-weight: 500;
}
