h4.h4title {
  font-size: 12px;
}
.checkIcon {
  width: 18px;
  margin-left: 0.5rem;
}
.block_box.d-nones::after {
  display: none;
}
.add_more_item {
  margin-top: 40px !important;
  margin-left: 10px !important;
}
.block_box .plus {
  cursor: pointer;
  position: absolute;
  left: 53px;
  top: -10px;
  color: white;
  z-index: 100;
  font-size: 48px;
}
.react-calendar {
  border: none !important;
  margin: auto !important;
}
.react-calendar button {
  font-weight: 600;
}
.react-calendar__month-view__days {
  padding-bottom: 20px !important;
}
.normal_time {
  padding: 10px 3%;
  text-align: center;
  font-weight: bold;
  border-radius: 4px;
  margin: 3px 0px;
  color: black;
  font-size: 15px;
  cursor: pointer;
}
.active_time {
  color: white;
  background: #3ab34a;
}
.first_time {
  color: #d0d0d0;
}
.week {
  display: flex;
  justify-content: center;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

p.weekTwo {
  margin: 0px 14px;
  padding: 13px;
  border-radius: 5px;
  background: #ebebeb;
  font-size: 15px;
  font-weight: bold;
}
.week h6,
.d-flex-week {
  display: flex;
  padding-top: 10px;
  margin-top: 10px;
  font-weight: 500;
  align-items: center;
}
.week_icon {
  width: 27px;
  height: 23px;
  margin-left: 3px;
}
#address_box {
  border: 1px solid gray;
  padding: 10px 24px 0px;
  border-radius: 10px;
  margin: 2px 0px;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.active {
  border-color: #3ab34a !important;
}
#address_box.active {
  background: #3ab34a !important;
  color: #fff;
}
p.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
  outline: none;
  display: flex;
  justify-content: space-between;
}

.block_box.d-nones.uploadPic {
  border: none;
  box-shadow: none;
  padding-top: 21px;
  border: 0p x solid black;
}

input.weekTwo {
  width: 13%;
  background: #dbdbdb;
  border: none;
  text-align: center;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  margin: 10px 5px 0px;
}
.homeArray {
  transform: rotate(180deg) !important;
}
.cartArrow {
  transform: rotate(180deg) !important;
}
.dateArraw {
  transform: rotate(180deg) !important;
}
.timeArray {
  transform: rotate(180deg) !important;
}
.repeatArray {
  transform: rotate(180deg) !important;
}
.uploadArray {
  transform: rotate(180deg) !important;
}
.alert-head.sc-ion-alert-ios {
  padding-top: 18px;
  padding-bottom: 18px;
}
.alert-head.sc-ion-alert-ios h2#alert-1-hdr {
  font-size: 21px;
  font-weight: 800;
}
button.alert-button.ion-focusable.ion-activatable.alert-button-role-cancel.sc-ion-alert-ios {
  border: none;
  font-weight: 800;
}

button.alert-button.ion-focusable.ion-activatable.secondary.sc-ion-alert-ios {
  color: #3ab34a;
  border: none;
}
.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-ios {
  position: absolute;
  top: 50%;
  margin-top: -70px;
}
img.list_arrow_icons {
  transform: rotate(-85deg);
  margin-left: 5px;
}
img.list_arrow_icons.repeat_icon {
  transform: rotate(0deg);
}
.item_inner {
  width: 94%;
  border-bottom: 1.5px solid #dadada;
  margin: auto;
}
.upload_image {
  height: auto !important;
}

.week h6,
.week .d-flex-week {
  margin: 0.5rem;
}
.block_box.d-nones.upload_box {
  padding: 0px;
  border: none;
  box-shadow: none;
}
img.upload_image {
  width: 92%;
  height: 93% !important;
  align-self: center;
  transform: scale(1.1);
  padding-top: 0.2rem;
  box-shadow: 0px 0px 5px #00000052;
  margin-top: 5%;
  border-radius: 5px;
}
.list_box {
  display: flex;
  justify-content: space-between;
  margin: 3px 0px;
  align-items: center;
  cursor: pointer;
}

.list_first {
  display: flex;
  align-items: center;
}
.list_first h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-left: 12px;
  text-transform: uppercase;
  color: #000000;
}
.list_first .ml-2 {
  margin-left: 33px;
}
.list_arrow_icon {
  transform: rotate(-86deg);
  color: #c9c9c9;
  align-self: center;
}
ion-thumbnail,
.icon {
  width: 30px;
  height: 30px;
  align-items: center;
  color: black;
}
.tab3-d-flex {
  display: flex;
  margin: 60px 20px 0px;
}
.tab3-d-flex img {
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 15px;
}
.tab3-d-flex p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #6d6d6d;
}

ion-button#schedule_pickup_button {
  border-radius: 8px;
  color: white;
  height: 45px;
  padding: -1rem;
}
.close {
  height: 0px;
  transition: all 0.3s;
}
.open {
  height: 150px;
  transition: all 0.3s;
}
input#addMoreItem {
  display: none;
}
.plus.fileUploadPlus {
  top: -3px;
}

.title_error {
  position: relative;
}
.title_error p {
  font-weight: 500;
  position: absolute;
  z-index: 1251252116234623463463246346364436436346;
  top: 2rem;
  font-size: 0.7rem;
  left: 0.8rem;
  filter: drop-shadow(1px 1px 1px #88888859);
}
ion-row.address_item.md.hydrated {
  cursor: pointer;
}
div#address_box p {
  white-space: pre-line;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}
.accordion_btn {
  display: block;
  width: 100%;
  background:none;
  border:none
}
.optional_box {
  display: flex;
  align-items: center;
}
.optional_text {
  color: rgb(165, 165, 165);
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
  margin-bottom: 0;
}
.item_inner.complete {
  border-color: #6bc677;
}
.item_icon_image {
  margin: auto;
  display: block;
  height: 45px;
}
.Cart_image::after {
  right: 2rem;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
  color: gray;
  background-color: white;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button:focus,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:focus {
  background-color: white;
}
span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: black;
}
button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  color: gray;
  background: #ececec;
}

.react-calendar__month-view__weekdays__weekday {
  color: black;
}
button.react-calendar__tile.react-calendar__month-view__days__day {
  color: black;
}
button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  color: #b9b9b9;
  background: #f0f0f0;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  /* color: #d41111; */
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: gray;
}
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: white;
}


button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  color: white;
  border-radius: 50% !important;
  max-width: 13.2857% !important;
}
.title {
  display: flex;
  justify-content: space-between;
}
.block_box .plus.Cart_image_plus {
  left: 2.3rem;
}
.addItem_closeBtn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 1px solid black;
  border-radius: 50%;
  font-size: 0.9rem;
  font-weight: bold;
  background: white;
}
.plus.edit.addItem_edit {
  left: 0.5rem;
}

button.accordion-button,button.accordion-button:focus,button.accordion-button:active{
  border:none;
  outline:none;
  box-shadow: none;
}
.upload_more_div{
  min-width: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem;
  height: 100%;
  margin-right: 0.5rem;
  box-shadow: 0 0 6px #9f9f9f;
}
.upload_text{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
 text-align: center;
}
.upload_image_show{
  height: 100px;
  margin-right: 0.5rem;
  min-width: -moz-fit-content;
  min-width: 90px!important;
  width: 90px;
  overflow: hidden;
  box-shadow: 0 0 6px #9f9f9f;
}
.qustionMark_box{
  display: flex;
   align-items:center
}
.questionMark_text{
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #6D6D6D;
}