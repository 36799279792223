.page_two_content {
  padding-bottom: 5rem;
}
.recyclabels_title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
  font-family: Inter;
  text-transform: capitalize;
  color: #000000;
  text-align: center;
  text-decoration: underline;
}
.blockTwoPage {
  overflow: auto;
  white-space: nowrap;
  padding:7px 7px 7px 5px;
  display: flex;
  flex-direction: column;
}
.block_box {
  min-width: 150px;
  width: 100%;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #e5e5e5;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.block_box .item_icon img{
  max-height: 45px;
  padding: 7px;
}
/* .block_box::after {
  content: "";
  font-size: 40px;
  text-align: center;
  width: 32px;
  padding-top: -10px;
  height: 32px;
  background: #3ab34a;
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  right: 1rem;
} */
.block_box .plus {
  display: none;
  cursor: pointer;
  position: absolute;
  left: 7px;
  top: -7px;
  color: white;
  z-index: 100;
  font-size: 48px;
}
/* .block_box img {
  margin: auto;
  display: block;
  height: 45px;
  position: absolute;
    left: 25%;
} */
.block_box p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  text-overflow: ellipsis;
  text-align: left;
}
.block_box p:last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: left!important;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3ab34a;
  margin: 2px 0px 0px;
}
.recyclables {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #000000;
}
.recyclables:first-child{
  margin-top: 0px;
}
.moddal {
  z-index: 10000;
  background: green;
  height: 200px;
  position: absolute;
  width: 100%;
  color: white;
  bottom: 0;
}

.addItem {
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  margin-top: 2%;
  justify-content: center;
}
img.images {
  height: 3rem;
}
.descrip {
  margin-left: 2rem;
}
.descrip p,
.descrip h3 {
  margin: 0px;
}
ion-button.ion-color.ion-color-success.ios.button.button-outline.ion-activatable.ion-focusable.hydrated,
ion-button.ion-color.ion-color-dark.ios.button.button-outline.ion-activatable.ion-focusable.hydrated {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}
.count {
  display: flex;
  justify-content: center;
}
.count p {
  font-size: 25px;
  padding: 5px 15px;
  font-weight: 500;
  margin: 10px 5px;
}

.count p:nth-child(2) {
  background: #80808054;
  border-radius: 8px;
  color: black;
}
.text-centers {
  text-align: center;
  font-weight: bold;
  margin: 0px 0px 20px;
}

.exit_btn {
  margin: 10px;
  position: absolute;
  right: 0;
  top: 0px;
  border-radius: 50%;
  color: white;
  width: 37px;
  background: green;
  font-size: 2rem;
  text-align: center;
  width: 47px;
  font-weight: bold;
  padding: 0rem;
}
.my-custom-class {
  padding-top: 70%;
  background: rgba(255, 255, 255, 0.123);
}
ion-badge.badgeStyle.ion-color.ion-color-danger.md.hydrated {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
}
.title_rate {
  width: 83%;
}
ion-button.bottomBtn.ion-color.ion-color-success.ios.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
  position: absolute;
  bottom: -4px;
  left: -3px;
  width: 100%;
}
ion-tab-button {
  width: auto;
}
ion-col.category_section {
  display: flex;
  justify-content: center;
}
.item_added_check .alert-head {
  padding-bottom: 0.6rem !important;
}
.schedule_fill_block_item {
  height: 140px;
  overflow-x: auto;
}
.block_box {
  cursor: pointer;
}

@media (min-width: 768px) {
  .my-custom-class {
    display: none;
  }
  .container {
    padding: 1rem;
  }
}
