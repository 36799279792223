.addItem_web_view {
  margin-top: 1rem;
  width: 72%;
  margin-left: 8rem;
  height: 88vh;
  overflow-y: auto;
  background: white;
}

.showItem_addItem {
  background-color: white;
  margin-top: 1rem;
  padding: 2rem;
  padding-top: 1rem;
  height: 90%;
  position: relative;
}
ion-button.ion-color.ion-color-success.md.button.button-full.button-solid.ion-activatable.ion-focusable.hydrated {
  position: absolute;
  bottom: 2.3rem;
  width: 84%;
}
.rangeInput {
  margin: 2rem;
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: rgb(0, 146, 0);
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.descriptLeft {
  margin-left: 1rem;
}
.btnColor {
  margin: 1rem 0rem 3rem;
  display: flex;
  justify-content: space-around;
}
ion-thumbnail.web_Ion_Icon.md.hydrated {
  width: 60px;
  height: 58px;
}
.addItem.web_item {
  padding-bottom: 1rem;
}
