.local_pickup_div {
  background: #f6faf5;
  background-image: url(https://ik.imagekit.io/y8s2vhk66ef/undraw_schedule_pnbk_1_1__pweVcgxrx.png?updatedAt=1628145634338), url(https://ik.imagekit.io/y8s2vhk66ef/Dark_Green_Abstract_1__76xy8MWRgvt.png?updatedAt=1628145633861);
  background-position: right bottom, left bottom;
  background-repeat: no-repeat, no-repeat;
  height: 100vh;
  background-size: 44rem,95rem;
;
}

.profileName_pic p {
  margin-bottom: 0px;
  margin-right: 1rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings: "case" on;
  color: #808080;
}

.profileName_pic {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: auto;
}

.person_circle_icon {
  font-size: 2rem;
  color: #257430;
}
.pickUp_view_local {
  margin-top: 1rem;
  height: 78vh;
}
p.pickups_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0rem;
  padding: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  padding-bottom: 1rem;
}
p.pickups_title span {
  cursor: pointer;
  text-align: center;
  width: 50%;
}
p.pickups_title .active {
  color: #3ab34a;
  margin-top: 0px;
  border-bottom: 2px solid green;
  padding-bottom: 0.5rem;
}
ul.pickups_list_ul {
  list-style: none;
  padding: 0px;
  text-align: center;
  margin-bottom: 10px;
  overflow: auto;
  height: 453px;
  background: #F6FAF5;
}
.pickups_list_ul li {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  text-align: start;
  margin-bottom: 1.5rem;
}
.pickups_list_ul li .main_view,
.pickups_list_ul li .details_view {
  display: flex;
  align-items: center;
}
.pickups_list_ul li .details_view {
  transition: height 0.3s;
  height: 0px;
  overflow: hidden;
}
.pickups_list_ul li .details_view.view {
  margin-top: .3rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: self-start;
}
.details_d_flex{
  display: flex;
  width: 100%;
}
.details_view_ques {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #5b5b5b;
  margin-right: 1rem;
  width: 31%;}
.details_view_ans {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #5b5b5b;
  width:69%;
}
.pickups_list_ul li p {
  margin: 0;
  text-align: start;
}

.pickups_list_ul li span:first-child {
  margin-right: 2rem;
  /* width: 50px; */
}
h5.pickup_details_day {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin: 0px;
  text-align: center;
  color:#5B5B5B;
}
h6.pickup_details_data {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  margin: 0px;
  color: #5b5b5b;
  text-align: center;
}
.pickup_details_name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;

  color: #5b5b5b;
}
.pickup_details_weight,
.pickup_details_price {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #3ab34a;
}

ion-icon.down_arrow {
  font-size: 1.7rem;
  margin-left: auto;
  cursor: pointer;
  align-self: self-start;
  color: #5B5B5B;
}
button.local_next_button.schedule_pickup_button {
  width: 340px;
  height: 63px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 0px;
}
.local_pending_status{
  background: none;
  box-shadow: none;
  justify-content: space-around;
}
.local_pending_status ion-chip{
  width: 30%;
  text-align: center;
  font-weight: 500;
}
ion-chip{
  font-weight: 500;
}
.local_pending_status ion-chip span{
  display: block;
  text-align: center;
  width: 100%;
  font-weight: 500;
  text-transform: uppercase;
}
button.accordion_btn {
  display: block;
  width: 100%;
  background:none;
  border:none
}
button.accordion_btn:hover{
  background: none;
  
} 
button.accordion_btn:focus{
  background: none!important;
  border: none!important;
  outline:none!important;
  box-shadow:none!important;
}
button.accordion_btn:active{
  background: none!important;
  border: none!important;
  outline:none!important
}
button.accordion-button,button.accordion-button:active,button.accordion-button:focus {
  background: none;
  border: none;
  box-shadow: none;
  width: 100%;
}
@media (max-width: 768px){
  .local_pickup_div{
    background-image: none;
  }
}